<template>
  <b-card no-body>
    <loading :show="is_loading">
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            class="mt-1 p-2"
            @submit.prevent="handleSubmit(submitForm)"
        >
          <b-row>
            <!-- Field: Name -->
            <b-col
                cols="12"
            >
              <validation-provider
                  #default="{errors}"
                  name="name"
                  rules="required"
              >
                <b-form-group
                    :label="$i18n.t('Role Name')"
                    label-for="role"
                >
                  <b-form-input
                      id="name"
                      v-model="name"
                      autofocus
                      :state="errors.length > 0 ? false:null"
                      trim
                      placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- PERMISSIONS -->
          <validation-provider
              #default="{errors}"
              name="permissions"
              rules=""
          >
            <b-card
                no-body
                class="border mt-1"
            >
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <feather-icon
                      icon="LockIcon"
                      size="18"
                  />
                  <span class="align-middle ml-50">{{ $i18n.t('Permission') }}</span>
                </b-card-title>
              </b-card-header>
              <b-row class="mb-1">
                <b-col
                    v-for="(modulePermissions,module) in modules"
                    :key="module"
                    cols="4"
                >
                  <b-card
                      class="p-1 mb-0"
                      no-body
                  >
                    <div class="pl-2 pr-2">
                      <h4>{{ module }}</h4>
                      <b-form-checkbox
                          v-for="(value,permission) in modulePermissions"
                          :key="module+'.'+permission"
                          v-model="modules[module][permission]"
                          class="mt-1 ml-1"
                          :checked="value"
                      >
                        {{ permission }}
                      </b-form-checkbox>
                    </div>
                  </b-card>
                </b-col>
                <b-col
                    v-if="showPermissionsSection"
                    cols="12"
                >
                  <hr>
                  <b-card
                      class="p-1"
                      no-body
                  >
                    <b-card-title class="font-medium-1">
                      {{ $i18n.t('additional_permissions') }}
                    </b-card-title>
                    <div
                        v-for="(actions,module) in permissions"
                        :key="module"
                        class="pl-2 pr-2"
                    >
                      <b-form-checkbox
                          v-for="(value,action) in actions"
                          :key="module+'-'+action"
                          v-model="permissions[module][action]"
                          class="mt-1"
                          :checked="value"
                      >
                        <h5>{{ module }}</h5>
                      </b-form-checkbox>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <b-row class="mt-2">
            <b-col>
              <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  type="submit"
              >
                {{ $i18n.t('Save Changes') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </loading>
  </b-card>
</template>

<script>
import {alphaNum, email, required} from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  data() {
    return {
      required,
      alphaNum,
      email,
      modules: [],
      permissions: [],
      name: '',
      is_loading: false,
      repository: RepositoryFactory.create('roles'),
    }
  },
  computed: {
    showPermissionsSection(){
      return (this.permissions && Object.keys(this.permissions).length > 0)
    }
  },
  created() {
    this.getUtilsPermissions()
  },
  methods: {
    async getUtilsPermissions() {
      this.is_loading = true
      await this.utilsRepository().permissions()
          .then(res => {
            this.modules = res.data.data.modules
            this.permissions = res.data.data.permissions
          })
      this.is_loading = false
    },
    submitForm() {
      const data = {
        name: this.name,
        modules: {...this.modules,...this.permissions},
      }
      this.repository.store(data).then(response => {
        this.$router.replace({name: 'roles'})
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Success'),
            icon: 'EditIcon',
            variant: 'success',
            text: response.data.message
          },
        })
      }).catch(error => {
        if (error.data && error.data.errors) {
          this.$refs.refFormObserver.setErrors(error.data.errors)
        }
      })
    }
  }
}
</script>
